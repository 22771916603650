import Header from "./Header";
import Footer from "./Footer";

const HomeLayout = ({children}) => {
  return (
    <div className="min-vh-100">
      <Header />

      <main>
        {children}
      </main>

      <Footer />
    </div>
  );
};

export default HomeLayout;
