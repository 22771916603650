import { Container } from "react-bootstrap";
import { useIntl } from "react-intl";

import Copyright from "./Copyright";
import Contact from "../components/Contact";
import img from "assets/images/home/img-footer.png";
import imgWebp from "assets/images/home/img-footer_2x.webp";

const Footer = () => {
  const { formatMessage } = useIntl();

  return (
    <footer id="contact-section" className="mt-230 pb-3 pb-sm-4">
      <div id="contact-section-mobile">
        <Container>        
          <div className="bg-success-light rounded-3 px-3 px-lg-64 position-relative pt-250 mx-lg-n20">
            <picture>
              <source
                srcSet={imgWebp}
                type="image/webp" />
              <source
                srcSet={img}
                type="image/png" />
              <img
                src={img}
                className="img-footer img-fluid position-absolute start-50 mt-n150 top-0 pointer-events-none"
                alt={formatMessage({ id: "contactUsHome" })} />
            </picture>

            <div className="pb-56">
              <Contact />
            </div>
            <div className="py-4 border-top border-gray">            
              <Copyright />
            </div>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
