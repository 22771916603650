import { Button } from "react-bootstrap";
import Icon from "components/Icon";
import { CopyToClipboard } from "react-copy-to-clipboard";

const contactList = [
  { icon: "phone", text: "+3 161 302 96 62" },
  { icon: "mail", text: "nijat.akhundov@janra-solutions.com" }
  // { icon: "location", text: "Dalsteindreef 6002, Diemen, Netherlands" }
];

const ContactList = ({ isHome }) => {
  return (
    <ul className="list-unstyled mb-0">
      {
        contactList.map((contact) => (
          <li
            className={`d-flex align-items-center justify-content-between ${isHome ? "mb-3 mb-sm-4" : "mb-3"}`}
            key={contact.icon}>
            <div className="d-inline-flex align-items-center">
              <Icon icon={contact.icon} className={` text-success ${isHome ? "sz-20 sz-sm-32 me-18 me-sm-4" : "sz-20 me-18"}`} />
              <strong className={`ff-display ${isHome ? "fw-500" : "fw-800"}`}>
                {contact.text}
              </strong>
            </div>
            <CopyToClipboard text={contact.text}>            
              <Button
                variant="link"
                className="p-0 h-auto text-gray"
                aria-label="copy">
                <Icon
                  icon="copy"
                  className={`${isHome ? "sz-4 sz-sm-32" : "sz-4"}`} />
              </Button>
            </CopyToClipboard>
          </li>
        ))
      }
    </ul>
  );
};

export default ContactList;
