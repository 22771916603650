import { useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { APP_ROUTE } from "utils/constants";

import empty from "assets/images/empty.png";

const TopMob = () => {
  const location = useLocation();

  const testPic = "https://images.unsplash.com/photo-1514933651103-005eec06c04b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fHJlc3RhdXJhbnR8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60";

  return (
    <div className="d-flex align-items-center">
      <div
        className="sz-42 rounded-circle me-3 overflow-hidden flex-grow-0 flex-shrink-0"
        style={{
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          ...( !testPic ? { backgroundImage: `url(${empty})` } : { backgroundImage: `url(${testPic})` } )
        }}></div>
      <div>
        <div>
          {location.pathname === APP_ROUTE.DASHBOARD && <h4 className="h4 mb-0 ff-base"><FormattedMessage id="dashboard" /></h4>}
          {location.pathname === APP_ROUTE.LIVE_TABLE && <h4 className="h4 mb-0 ff-base"><FormattedMessage id="liveTable" /></h4>}
          {location.pathname === APP_ROUTE.PAYMENTS && <h4 className="h4 mb-0 ff-base"><FormattedMessage id="payments" /></h4>}
          {location.pathname === APP_ROUTE.TABLE_MANAGEMENT && <h4 className="h4 mb-0 ff-base"><FormattedMessage id="tableManagement" /></h4>}
          {location.pathname === APP_ROUTE.FEEDBACKS && <h4 className="h4 mb-0 ff-base"><FormattedMessage id="feedbacks" /></h4>}
          {location.pathname === APP_ROUTE.SETTINGS && <h4 className="h4 mb-0 ff-base"><FormattedMessage id="settings" /></h4>}
          {location.pathname === APP_ROUTE.ABOUT && <h4 className="h4 mb-0 ff-base"><FormattedMessage id="about" /></h4>}
        </div>
        <p className="mb-0 fs-10 text-secondary">
          <FormattedMessage id="seeDetails" />
        </p>
      </div>
    </div>
  );
};

export default TopMob;
