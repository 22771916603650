import { Navbar, Container, Row, Col, Button } from "react-bootstrap";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import Icon from "components/Icon";

import logo from "assets/images/Tapp.svg";
import logoWhite from "assets/images/Tapp-w.svg";

import MenuMob from "./MenuMob";
import ContactModal from "./ContactModal";

const Header = ({ brand }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showContact, setShowContact] = useState(false);

  const handleCloseContact = () => setShowContact(false);
  const handleShowContact = () => setShowContact(true);

  return (
    <>
      <MenuMob show={show} onHide={handleClose} />
      <ContactModal show={showContact} onHide={handleCloseContact} />

      <Navbar className="mt-44 mt-lg-32 pt-4 pb-3">
        <Container className="d-block">
          <Row className="align-items-center">
            <Col xs={6}>
              <Navbar.Brand href="/">
                {brand === "success" && <img src={logo} alt="Tapp"/>}
                {brand === "white" && <img src={logoWhite} alt="Tapp"/>}                
              </Navbar.Brand>
            </Col>
            <Col xs={6}>
              <div
                className="d-none d-lg-block text-end"
                onClick={handleShowContact}>
                <Button variant="outline-success">
                  <FormattedMessage id="contactUs" />
                </Button>
              </div>

              <div className="d-lg-none text-end">
                <Button
                  variant="menu"
                  onClick={handleShow}>
                  <Icon icon="menu" className="sz-4" />
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
