import { Button } from "react-bootstrap";
import Icon from "components/Icon";
import { CopyToClipboard } from "react-copy-to-clipboard";

const contactList = [
  { icon: "phone", text: "+994 12 222 22 22" },
  { icon: "mail", text: "hello@bilimbaku.com" },
  { icon: "location", text: "Nizami küçəsi 12, Bakı" }
];

const ContactList = () => {
  return (
    <ul className="list-unstyled mb-0">
      {
        contactList.map((contact) => (
          <li
            className="d-flex align-items-center justify-content-between mb-3"
            key={contact.icon}>
            <div className="d-inline-flex align-items-center">
              <Icon icon={contact.icon} className="text-success sz-20 me-18" />
              <strong className="ff-display fw-800">
                {contact.text}
              </strong>
            </div>
            <CopyToClipboard text={contact.text}>            
              <Button
                variant="link"
                className="p-0 text-gray h-auto"
                aria-label="copy">
                <Icon
                  icon="copy"
                  className="sz-4" />
              </Button>
            </CopyToClipboard>
          </li>
        ))
      }
    </ul>
  );
};

export default ContactList;
