import { Form } from "react-bootstrap";
import Icon from "components/Icon";

const Search = () => {
  return (
    <Form.Group className="position-relative">
      <div className="position-relative d-flex align-items-center">
        <Form.Label className="mb-0 position-absolute start-0 ms-4 top-50 translate-middle-y">
          <Icon icon="s" className="text-success"/>
        </Form.Label>
        <Form.Control
          type="text"
          name="name"
          className="form-control_search"
          placeholder="Search ..."
        />
      </div>
    </Form.Group>
  );
};

export default Search;
