import { Modal, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Icon from "components/Icon";
import qr from "assets/images/home/qr.png";

const ViewDemoModal = ({ show, onHide }) => {
  return (
    <Modal show={show}onHide={onHide} size="xs">
      <Modal.Header className="pb-32">
        <Modal.Title>
          &nbsp;
        </Modal.Title>
        <Button
          variant="success-light"
          className="btn-close"
          onClick={onHide}>
          <Icon icon="close" className="sz-22"/>
        </Button>
      </Modal.Header>
      <Modal.Body className="pt-10 text-center">
        <img src={qr} alt="QR" className="w-198 mx-auto mb-40" />

        <div className="text-start p-4 bg-dark rounded-4 text-white position-relative tip">
          <h4 className="h4 fw-bold text-uppercase m-0 lh-1">
            <FormattedMessage id="scanTheDemo" />
          </h4>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewDemoModal;
