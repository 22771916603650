import { Modal, Button } from "react-bootstrap";
import Icon from "components/Icon";

import Contact from "components/Contact";

const ContactModal = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header className="pb-3 pb-lg-32">
        <Modal.Title>Have questions? Write and ask from us.</Modal.Title>
        <Button variant="success-light" className="btn-close" onClick={onHide}>
          <Icon icon="close" className="sz-22" />
        </Button>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <Contact />
      </Modal.Body>
    </Modal>
  );
};

export default ContactModal;
