import { Nav } from "react-bootstrap";
import Icon from "components/Icon";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { APP_ROUTE } from "utils/constants";

const Navigate = () => {
  return (
    <Nav className="flex-column vstack">
      <Nav.Item className="mb-3">
        <NavLink
          to={APP_ROUTE.DASHBOARD}
          className={({ isActive }) =>
            "nav-link d-flex align-items-center" + (isActive ? " active" : "")
          }>
          <Icon icon="menu-dashboard" className="sz-20" />
          <span className="ms-3">
            <FormattedMessage id="dashboard" />
          </span>
        </NavLink>
      </Nav.Item>
      <Nav.Item className="mb-3">
        <NavLink
          to={APP_ROUTE.LIVE_TABLE}
          className={({ isActive }) =>
            "nav-link d-flex align-items-center" + (isActive ? " active" : "")
          }>
          <Icon icon="menu-live" className="sz-20" />
          <span className="ms-3">
            <FormattedMessage id="liveTable" />
          </span>
        </NavLink>
      </Nav.Item>
      <Nav.Item className="mb-3">
        <NavLink
          to={APP_ROUTE.PAYMENTS}
          className={({ isActive }) =>
            "nav-link d-flex align-items-center" + (isActive ? " active" : "")
          }>
          <Icon icon="menu-payments" className="sz-20" />
          <span className="ms-3">
            <FormattedMessage id="payments" />
          </span>
        </NavLink>
      </Nav.Item>
      <Nav.Item className="mb-3">
        <NavLink
          to={APP_ROUTE.TABLE_MANAGEMENT}
          className={({ isActive }) =>
            "nav-link d-flex align-items-center" + (isActive ? " active" : "")
          }>
          <Icon icon="menu-table" className="sz-20" />
          <span className="ms-3">
            <FormattedMessage id="tableManagement" />
          </span>
        </NavLink>
      </Nav.Item>
      <Nav.Item className="mb-3">
        <NavLink
          to={APP_ROUTE.FEEDBACKS}
          className={({ isActive }) =>
            "nav-link d-flex align-items-center" + (isActive ? " active" : "")
          }>
          <Icon icon="menu-feedbacks" className="sz-20" />
          <span className="ms-3">
            <FormattedMessage id="feedbacks" />
          </span>
        </NavLink>
      </Nav.Item>
      <Nav.Item className="mb-3">
        <NavLink
          to={APP_ROUTE.SETTINGS}
          className={({ isActive }) =>
            "nav-link d-flex align-items-center" + (isActive ? " active" : "")
          }>
          <Icon icon="menu-settings" className="sz-20" />
          <span className="ms-3">
            <FormattedMessage id="settings" />
          </span>
        </NavLink>
      </Nav.Item>
      <Nav.Item className="mb-3 mt-auto">
        <NavLink
          to={APP_ROUTE.ABOUT}
          className={({ isActive }) =>
            "nav-link d-flex align-items-center" + (isActive ? " active" : "")
          }>
          <Icon icon="info" className="sz-20" />
          <span className="ms-3">
            <FormattedMessage id="about" />
          </span>
        </NavLink>
      </Nav.Item>
    </Nav>
  );
};

export default Navigate;
