// import { Container, Row, Col } from "react-bootstrap";

import Profile from "./Profile";
import Navigate from "./Navigate";

const Sidebar = () => {
  return (
    <aside className="sidebar min-vh-100 d-flex flex-column">
      <div className="p-40">
        <Profile />
      </div>      
      <div className="pt-2 pb-4 pe-4 vstack">
        <Navigate />
      </div>
    </aside>
  );
};

export default Sidebar;
