import { Button, Form, Row, Col, Alert } from "react-bootstrap";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import emailjs from "@emailjs/browser";

import { Formik } from "formik";
import { getContactFormSchema } from "utils/validation/yupContactForm";
import SuccesfullMessageModal from "components/SuccesfullMessageModal";

const ContactForm = ({ isHome }) => {
  const { formatMessage } = useIntl();

  const [showSuccess, setShowSuccess] = useState(false);

  const handleCloseSuccess = () => setShowSuccess(false);
  const handleShowSuccess = () => setShowSuccess(true);

  const schema = getContactFormSchema(formatMessage);

  const handleSubmit = async (values, { setStatus, setErrors }) => {
    try {
      emailjs
            .send(
              "default_service",
              "template_t4toz38",
              values,
              "aQ8TM5s2gMXXWU21y"
            )
            .then(
              (result) => {
                // console.log(result.text);
                handleShowSuccess();
              },
              (err) => {
                console.log(err.text);
                console.error(err);
                setStatus(err.message);
              }
            );
    } catch (err) {
      console.error(err);
      setStatus(err.message);
    }
  };

  return (
    <>
      <SuccesfullMessageModal show={showSuccess} onHide={handleCloseSuccess} />

      <Formik
        validateOnChange
        initialValues={{ name: "", email: "", message: "" }}
        validationSchema={schema}
        onSubmit={handleSubmit}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isValid,
          handleSubmit,
          dirty,
          status
        }) => (
          <Form className="form" onSubmit={handleSubmit}>
            {status && (
              <Alert variant="danger">{status}</Alert >
            )}

            <Row>
              <Col lg={12}>
                <Form.Label htmlFor="name" className={`${isHome ? "fs-14 mb-2 fw-bold text-success" : ""}`}>
                  <FormattedMessage id="nameSurname" />{isHome ? "*" : ""}
                </Form.Label>
              </Col>
              <Col lg={12}>
                <Row>
                  <Col lg={isHome ? 6 : 12}>
                    <Row>
                      <Col lg={isHome ? 12 : 6} className={`${isHome ? "mb-4" : "mb-3"}`}>
                        <Form.Group className="position-relative">
                          <div className="position-relative">
                            <Form.Control
                              id="name"
                              type="text"
                              name="name"
                              placeholder="Name"
                              className={`py-14 py-lg-3 ${touched.name ? "is-touch " : ""} ${
                                errors.name && touched.name ? " is-invalid" : ""
                              }`}
                              value={values.name}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </div>
                          {errors.name && touched.name && (
                            <Form.Control.Feedback type="invalid" className="position-absolute start-0 top-100 mt-1">
                              {errors.name}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={isHome ? 12 : 6} className={`${isHome ? "mb-4" : "mb-3"}`}>
                        <Form.Group className="position-relative">
                          <div className="position-relative">
                            <Form.Control
                              type="text"
                              name="email"
                              placeholder="E-mail"
                              className={`py-14 py-lg-3 ${touched.email ? "is-touch " : ""} ${
                                errors.email && touched.email ? " is-invalid" : ""
                              }`}
                              value={values.email}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </div>
                          {errors.email && touched.email && (
                            <Form.Control.Feedback type="invalid" className="position-absolute start-0 top-100 mt-1">
                              {errors.email}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={isHome ? 6 : 12} className={`${isHome ? "mb-4" : "mb-3"}`}>
                    <Form.Group className={`position-relative ${isHome ? "h-100" : ""}`}>
                      <div className={`position-relative ${isHome ? "h-100" : ""}`}>
                        <Form.Control
                          type="text"
                          as="textarea"
                          rows="5"
                          name="message"
                          placeholder="Your message..."
                          className={`py-14 py-lg-3 ${touched.message ? "is-touch " : ""} ${
                            errors.message && touched.message ? " is-invalid" : ""
                          } ${isHome ? "h-100" : ""}`}
                          value={values.message}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                      {errors.message && touched.message && (
                        <Form.Control.Feedback type="invalid" className="position-absolute start-0 top-100 mt-1">
                          {errors.message}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Button
              type="submit"
              variant="success"
              className="mt-2"
              disabled={!(isValid && dirty)}>
                {isHome ? <FormattedMessage id="sendMessageShort" /> : <FormattedMessage id="sendMessage" />}
            </Button>            
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ContactForm;
