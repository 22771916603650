import SvgSprite from "assets/svg/sprite.svg";

const Icon = ({ className, icon }) => {
  return (
    <svg className={`icon icon-${icon} ${className}`}>
      <use xlinkHref={`${SvgSprite}#icon-${icon}`}></use>
    </svg>
  );
};

export default Icon;
