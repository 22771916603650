import { Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import ContactList from "./ContactList";
import ContactForm from "./ContactForm";

const Contact = () => {
  return (
    <Row>
      <Col md={6} lg={5}>
        <div className="pe-xl-28">
          <h2 className="h1 display-sm-4 mb-4 my-xl-40 ff-base">
            <FormattedMessage id="contactUsHome" />
          </h2>
          <ContactList isHome={true} />
        </div>
      </Col>
      <Col md={6} lg={7}>
        <div className="bg-white p-3 px-xl-40 py-xl-40 rounded-4 ms-lg-n2">
          <ContactForm isHome={true} />
        </div>
      </Col>
    </Row>
  );
};

export default Contact;
