import { Offcanvas, Button } from "react-bootstrap";
import Icon from "components/Icon";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import { logout } from "redux/slices/userSlice";

import Profile from "./Profile";
import Navigate from "./Navigate";

const SidebarMob = ({ show, onHide }) => {
  const dispatch = useDispatch();

  return (
    <Offcanvas show={show} onHide={onHide} className="offcanvas_success">
      <Offcanvas.Header className="p-40 justify-content-center">
        <Profile />
        <Button variant="white" className="btn-close" onClick={onHide}>
          <Icon icon="close" className="sz-22" />
        </Button>
      </Offcanvas.Header>
      <Offcanvas.Body className="pt-2 ps-0 pb-4">        
        <Navigate />

        <div className="nav mt-auto">
          <div className="nav-item">
            <Button
              variant="link"
              className="nav-link w-100 justify-content-start h-auto"
              onClick={() => dispatch(logout())}>
              <Icon icon="logout" className="sz-20" />
              <span className="ms-3">
                <FormattedMessage id="logOut" />
              </span>
            </Button>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SidebarMob;
