import { Navbar, Button, Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import Icon from "components/Icon";

import logo from "assets/images/Tapp.svg";
import Menu from "./Menu";
import MenuMob from "./MenuMob";
import ViewDemoModal from "../components/ViewDemoModal";

const Header = () => {
  const [showMenuMob, setShowMenuMob] = useState(false);

  const handleCloseMenuMob = () => setShowMenuMob(false);
  const handleShowMenuMob = () => setShowMenuMob(true);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = (e) => {
    const header = document.getElementById("home-navbar");
    const scrollTop = window.scrollY;
    scrollTop >= 144 ? header.classList.add("position-sticky") : header.classList.remove("position-sticky");
  };

  return (
    <>
      <ViewDemoModal
        show={show}
        onHide={handleClose} />

      <MenuMob
        show={showMenuMob}
        onHide={handleCloseMenuMob} />

      <Navbar id="home-navbar" expand="lg" className="py-40">
        <Container className="d-block">
          <Row className="justify-content-center">
            <Col lg={11}>
              <Row className="align-items-center">
                <Col xs={6} lg={2}>
                  <Navbar.Brand href="/">
                    <img src={logo} alt="Tapp"/>
                  </Navbar.Brand>
                </Col>

                <Navbar.Collapse id="home-navbar-nav" className="col-lg-8 col-6">
                  <div className="offcanvas-header pb-32 d-lg-none">
                    <div className="offcanvas-title h5">
                      <a href="/" className="navbar-brand">
                        <img src={logo} alt="Tapp"/>
                      </a>
                    </div>
                    <Navbar.Toggle
                      aria-controls="home-navbar-nav"
                      className="btn-close btn btn-success-light">
                      <Icon icon="close" className="sz-22" />
                    </Navbar.Toggle>
                  </div>

                  <Menu />
                </Navbar.Collapse>

                <Col xs={6} lg={2}>
                  <div className="d-flex align-items-center justify-content-end">
                    <Button
                      variant="success"
                      onClick={handleShow}>
                      <FormattedMessage id="viewDemo" />
                    </Button>
                    <div className="d-lg-none text-end">                      
                      <Button
                        variant="menu"
                        className="ms-2"
                        onClick={handleShowMenuMob}
                        aria-label="menu">
                        <Icon icon="menu" className="sz-4" />
                      </Button>
                      {/* <Navbar.Toggle
                        aria-controls="home-navbar-nav"
                        className="btn btn-menu ms-2">
                        <Icon icon="menu" className="sz-4" />
                      </Navbar.Toggle> */}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
