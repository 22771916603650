import { Suspense, lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { APP_ROUTE } from "./utils/constants";

import { Spinner } from "./components/Spinner";

const Home = lazy(() => import("./views/Home/Home"));

const Login = lazy(() => import("./views/Login/Login"));
const ResetPasswordEmail = lazy(() => import("./views/Login/ResetPasswordEmail"));
const ResetPasswordCode = lazy(() => import("./views/Login/ResetPasswordCode"));
const ResetPassword = lazy(() => import("./views/Login/ResetPassword"));
const ResetPasswordSuccess = lazy(() => import("./views/Login/ResetPasswordSuccess"));

const Dashboard = lazy(() => import("./views/Dashboard/Dashboard"));
const LiveTable = lazy(() => import("./views/LiveTable/LiveTable"));
const Payments = lazy(() => import("./views/Payments/Payments"));
const TableManagement = lazy(() => import("./views/TableManagement/TableManagement"));
const Feedbacks = lazy(() => import("./views/Feedbacks/Feedbacks"));
const Settings = lazy(() => import("./views/Settings/Settings"));
const About = lazy(() => import("./views/About/About"));

const PrivacyPolicy = lazy(() => import("./views/Page/PrivacyPolicy"));
const AboutTapp = lazy(() => import("./views/Page/AboutTapp"));
const TermsConditions = lazy(() => import("./views/Page/TermsConditions"));
const Trust = lazy(() => import("./views/Page/Trust"));
const CookiesPolicy = lazy(() => import("./views/Page/CookiesPolicy"));

export const MainRoutes = () => {  
  return (
    <Suspense fallback={<Spinner full />}>
      <Routes>
        <Route path={APP_ROUTE.HOME} element={<Home />} />

        <Route path={APP_ROUTE.DASHBOARD} element={<Dashboard />} />
        <Route path={APP_ROUTE.LIVE_TABLE} element={<LiveTable />} />
        <Route path={APP_ROUTE.PAYMENTS} element={<Payments />} />
        <Route path={APP_ROUTE.TABLE_MANAGEMENT} element={<TableManagement />} />
        <Route path={APP_ROUTE.FEEDBACKS} element={<Feedbacks />} />
        <Route path={APP_ROUTE.SETTINGS} element={<Settings />} />
        <Route path={APP_ROUTE.ABOUT} element={<About />} />

        <Route path={APP_ROUTE.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={APP_ROUTE.ABOUT_TAPP} element={<AboutTapp />} />
        <Route path={APP_ROUTE.TERMS_CONDITIONS} element={<TermsConditions />} />
        <Route path={APP_ROUTE.TRUST} element={<Trust />} />
        <Route path={APP_ROUTE.COOKIES_POLICY} element={<CookiesPolicy />} />

        <Route
          path={APP_ROUTE.ENTRY}
          element={<Navigate replace to={APP_ROUTE.DASHBOARD} />}
        />
      </Routes>
    </Suspense>
  );
};

export const LoginRoutes = () => {
  return (
    <Suspense fallback={<Spinner full />}>
      <Routes>
        <Route path={APP_ROUTE.HOME} element={<Home />} />

        <Route path={APP_ROUTE.LOGIN} element={<Login />} />
        <Route path={APP_ROUTE.FORGOT} element={<ResetPasswordEmail />} />
        <Route path={APP_ROUTE.VERIFY_CODE} element={<ResetPasswordCode />} />
        <Route path={APP_ROUTE.RESET_PASSWORD} element={<ResetPassword />} />
        <Route path={APP_ROUTE.RESET_PASSWORD_SUCCESS} element={<ResetPasswordSuccess />} />

        <Route path={APP_ROUTE.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={APP_ROUTE.ABOUT_TAPP} element={<AboutTapp />} />
        <Route path={APP_ROUTE.TERMS_CONDITIONS} element={<TermsConditions />} />
        <Route path={APP_ROUTE.TRUST} element={<Trust />} />
        <Route path={APP_ROUTE.COOKIES_POLICY} element={<CookiesPolicy />} />

        <Route path="*" element={<Navigate replace to={APP_ROUTE.ENTRY} />} />
      </Routes>
    </Suspense>
  );
};
