import { Container, Row, Col } from "react-bootstrap";
import Header from "./Header";
import Footer from "./Footer";

const MainLayoutLogin = ({children}) => {
  return (
    <div className="min-vh-100 d-flex flex-column position-relative bg-0">
      <div className="position-relative z-100">
        <Header brand={"white"} />
      </div>
      <main className="position-relative z-100 my-auto">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col xs={12} md={8} lg={8} xxl={6}>
              <div className="rounded-3 bg-white p-lg-40 mw-544 mx-auto decor">
                <div className="rounded-3 bg-white shadow py-40 px-4 px-lg-56 text-center">
                  {children}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
      <div className="position-relative z-100 text-white mt-auto">
        <Footer />
      </div>
    </div>
  );
};

export default MainLayoutLogin;
