// import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import empty from "assets/images/empty.png";

const Profile = () => {
  const testPic = "https://images.unsplash.com/photo-1514933651103-005eec06c04b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fHJlc3RhdXJhbnR8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60";

  return (
    <div className="text-center">
      <div
        className="sz-108 rounded-circle bg-white overflow-hidden mb-1 mx-auto"
        style={{
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          ...( !testPic ? { backgroundImage: `url(${empty})` } : { backgroundImage: `url(${testPic})` } )
        }}></div>

        <h4 className="mb-0 ff-base">Gold Spoon</h4>
        <Link
          to={{ pathname: "info@goldspoon.az" }}
          target="_blank"
          rel="noreferrer"
          className="text-reset text-decoration-none opacity-50 hover:opacity-100">
          <small className="d-block fs-10">info@goldspoon.az</small>
        </Link>
    </div>
  );
};

export default Profile;
