import { Button, Dropdown } from "react-bootstrap";
import Icon from "components/Icon";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { logout } from "redux/slices/userSlice";

import Search from "components/Search";
import TopMob from "components/TopMob";
import SidebarMob from "./SidebarMob";
import SearchMob from "./SearchMob";

const Top = () => {
  const dispatch = useDispatch();

  const { userStore: { languageCode } } = useSelector((state) => ({ userStore: state.userStore }));
  const [activeLanguage, setActiveLanguage] = useState(languageCode);

  const toggleLanguage = (languageCode) => {
    setActiveLanguage(languageCode);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showSearch, setShowSearch] = useState(false);

  const handleCloseSearch = () => setShowSearch(false);
  const handleShowSearch = () => setShowSearch(true);

  return (
    <>
      <SidebarMob show={show} onHide={handleClose} />
      <SearchMob show={showSearch} onHide={handleCloseSearch} />

      <div className="px-20 px-md-3 px-lg-20 px-xl-5 mt-44 py-4 py-lg-28 mt-lg-0 mb-lg-2 d-flex align-items-center">
        <div className="d-lg-none">
          <TopMob />
        </div>

        <div className="d-none d-lg-block vstack">
          <Search />
        </div>

        <div className="d-inline-flex align-items-center ms-auto">
          <Button variant="white" className="btn-sq ms-2 d-lg-none" onClick={() => handleShowSearch()}>
            <Icon icon="s" className="text-success" />
          </Button>

          <Dropdown align="end">
            <Dropdown.Toggle variant="white" id="dropdown-basic" className="btn-sq ms-2 text-capitalize">
              {activeLanguage}
            </Dropdown.Toggle>

            <Dropdown.Menu className="text-center w-40">
              <Dropdown.Item onClick={() => toggleLanguage("En")}>En</Dropdown.Item>
              <Dropdown.Item onClick={() => toggleLanguage("Nl")}>Nl</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Button variant="white" className="btn-sq ms-2 d-none d-lg-inline-flex" onClick={() => dispatch(logout())}>
            <Icon icon="logout" className="text-danger sz-20" />
          </Button>

          <Button variant="menu" className="ms-2 d-lg-none" onClick={() => handleShow()}>
            <Icon icon="menu" className="sz-4 d-lg-none" />
          </Button>
        </div>
      </div>
    </>
  );
};

export default Top;
