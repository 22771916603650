import { createSlice } from "@reduxjs/toolkit";
import { authApi } from "../services/authApi";

/* REFRESH TOKEN ON CLIENT */
// const minutesToMs = min => min * 60 * 1000;
// const notExpireUntilSet = Date.now() + minutesToMs(60*24)

const initialState = {
  user: {
    name: "",
    email: ""
  },
  usersList: [],
  token: {
    refresh: null,
    // access: null
    access: false
  },
  isNewUser: false,
  isLogin: false,
  languageCode: "en",
  rememberMe: true,
  isLoginTest: false,
  menu: null,
  /* REFRESH TOKEN ON CLIENT */
  defaultCountryCode: "EN"
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: () => initialState,
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
      /* REFRESH TOKEN ON CLIENT default for an hour */
      // state.expiresAt = Date.now() + minutesToMs(60)
    },
    setDefaultCountryCode: (state, action) => {
      state.defaultCountryCode = action.payload;
    },
    setRememberMe: (state, action) => {
      state.rememberMe = action.payload;
    },
    setLoginTest: (state, action) => {
      state.isLoginTest = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
        state.token = action.payload.data;
        /* REFRESH TOKEN ON CLIENT */
        // state.expiresAt = Date.now() + minutesToMs(30)
        state.isLogin = true;
      })
      .addMatcher(
        authApi.endpoints.registerUser.matchFulfilled,
        (state, action) => {
          state.token = action.payload.data;
          /* REFRESH TOKEN ON CLIENT */
          // state.expiresAt = Date.now() + minutesToMs(30)
        }
      )
      .addMatcher(authApi.endpoints.login.matchRejected, (state, action) => {
        console.log("rejected:", action);
      })
      .addMatcher(authApi.endpoints.login.matchPending, (state, action) => {
        console.log("pending:", action);
      })
      .addMatcher(
        authApi.endpoints.fetchUser.matchFulfilled,
        (state, action) => {
          state.user = action.payload.data;
          state.languageCode = action.payload.data.language_code;
        }
      )
      .addMatcher(
        authApi.endpoints.updateUserData.matchFulfilled,
        (state, action) => {
          state.languageCode = action.payload.data.language_code;
        }
      );
  }
});

const { actions, reducer } = userSlice;
export const {
  logout,
  setUser,
  setToken,
  setDefaultCountryCode,
  setRememberMe,
  setLoginTest
} = actions;
export default reducer;
