import { Modal, Button } from "react-bootstrap";
import Icon from "components/Icon";
import { FormattedMessage } from "react-intl";

const SuccesfullMessageModal = ({ show, onHide }) => {
  const testPic = "https://assets.materialup.com/uploads/cdfce6f5-af30-405d-9740-71c3aefb6a3b/preview.gif";

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header className="pt-1 pb-32">
        <Modal.Title>&nbsp;</Modal.Title>
        <Button
          variant="success-light"
          className="btn-close"
          onClick={onHide}>
          <Icon icon="close" className="sz-22" />
        </Button>
      </Modal.Header>
      <Modal.Body className="pt-0 text-center">
        <div
          className="sz-124 rounded-circle mx-auto mb-32 overflow-hidden"
          style={{
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            ...( !testPic ? { backgroundColor: "#ffffff" } : { backgroundImage: `url(${testPic})` } )
          }}></div>
        <h2 className="h2">
          <FormattedMessage id="messageSentSuccesfully" values={{ tag: <br /> }} />
        </h2>
      </Modal.Body>
    </Modal>
  );
};

export default SuccesfullMessageModal;
