import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithReauth } from "./reAuth";
import { AUTH_API } from "utils/constants";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: AUTH_API.TOKEN,
        method: "POST",
        body: credentials
      })
    }),
    updateUserData: builder.mutation({
      query: (credentials) => ({
        url: AUTH_API.ME,
        method: "PATCH",
        body: credentials
      })
    }),
    fetchUser: builder.mutation({
      query: () => ({ url: AUTH_API.ME, method: "GET" })
    }),
    registerUser: builder.mutation({
      query: (credentials) => ({
        url: AUTH_API.SIGNUP,
        method: "POST",
        body: credentials
      })
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: AUTH_API.PASSWORD_RESET,
        method: "POST",
        body
      })
    }),
    resetConfirmPassword: builder.mutation({
      query: (body) => ({
        url: `${AUTH_API.PASSWORD_RESET_CONFIRM}${body.uid}/${body.token}/`,
        method: "POST",
        body
      })
    }),
    contactUs: builder.mutation({
      query: (body) => ({
        url: AUTH_API.CONTACT_US,
        method: "POST",
        body
      })
    })
  })
});

export const {
  useLoginMutation,
  useUpdateUserDataMutation,
  useGetCountriesQuery,
  useRegisterUserMutation,
  useFetchUserMutation,
  useResetPasswordMutation,  
  useResetConfirmPasswordMutation,
  useContactUsMutation
} = authApi;
