import { LOCALES } from "../locales";

const en = {
  [LOCALES.EN]: {    
    // Login.js
    wellcome: "Welcome, to Tapp  application!",
    signIn: "Sign in",
    emailRequired: "Email is a required field",
    emailValid: "Email must be a valid email",
    passRequired: "Password is a required field",
    nameRequired: "Name is a required field",
    fieldIsRequired: "The field is required",
    codeIsRequired: "Code is a required field",
    confirmPasswordIsRequired: "Confirm Password is a required field",
    passAndConfirmPassMustMatch: "Password and Confirm Password must match",
    atLeast8Characters: "Must contain at least 8 characters, One Uppercase, One Lowercase, One Number or One special case Character",
    rememberMe: "Remember me",
    forgotPassword: "Forgot password?",
    password: "Password",
    contactUs: "Contact Us",
    copyright: "All rights reserved",
    sendCode: "Send code",
    newPassword: "New password.",
    changePassword: "Change your password.",
    newPasswordMeta: "Define your new password {tag} twice",
    changePasswordMeta: "Enter your email to receive {tag} the OTP code",
    changePasswordCodeMeta: "Enter the OTP code send to {tag} your e-mail",
    confirm: "Confirm",
    cancel: "Cancel",
    resend: "Resend code after",
    gotToLogin: "Go to login page",
    passwordChangeSuccesfully: "Password changed succesfully!",
    phoneNumberIsRequired: "Phone number is a required field",
    mobileNumberNotValid: "Mobile number is not valid",
    nameSurname: "Name and surname",
    // Menu
    privacyPolicy: "Privacy Policy",
    aboutTapp: "About Tapp",
    termsConditiions: "Terms & Conditiions",
    trust: "Trust",
    cookiesPolicy: "Cookies Policy",
    // Navigate
    dashboard: "Dashboard",
    liveTable: "Live table",
    payments: "Payments",
    tableManagement: "Table management",
    feedbacks: "Feedbacks",
    settings: "Settings",
    about: "About Tapp",
    logOut: "Log out",
    seeDetails: "See the details",
    // Contact
    sendMessage: "Send message",
    sendMessageShort: "Send",
    messageSentSuccesfully: "Message sent {tag} succesfully!",
    // Settings
    profileInfo: "Profile info",
    bankDetails: "Bank details",
    changePasswordS: "Change password",
    changePicture: "Change picture",
    deletePicture: "Delete picture",
    changeCardDetails: "Change card details",
    eMail: "E-mail",
    phone: "Phone",
    restaurantName: "Restaurant name",
    uploadMenu: "Upload menu",
    fileRequired: "*This section is reqired. The file must be a .pdf and shouldn’t exceed 10mb.",
    cardHolder: "Card holder",
    cardNumber: "Card number",
    expiryDate: "Expiry date",
    cvc: "CVC/CVV",
    eMailAddress: "E-mail address",
    zip: "ZIP/Postal code",
    city: "City",
    country: "Country",
    address: "Address",
    // Dashboard
    areShureRefund: "Are you sure to refund the",
    attention: "Attention!",
    refund: "Refund",
    fullRefund: "Full refund",
    refundCustomAmount: "Refund custom amount",
    refundedSuccesfully: "Refunded {tag} succesfully!",
    weeklyStatisticsTable: "Weekly statistics table",
    pleaseRefundAmount: "Please, enter the refund amount.",
    moreInformation: "More information",
    bill: "Bill",
    welcomeUser: "Welcome",
    export: "Export",
    openFilter: "Open filter",
    filter: "Filter",
    ratings: "Ratings",
    comments: "Comments",
    lastPaymentDate: "Last payment date",
    fullPaymentDate: "Full payment date",
    tableN: "Table №",
    monthlyRevenue: "Monthly revenue",
    monthlyTips: "Monthly tips",
    revenueOrders: "Revenue from orders",
    countOrders: "Count of monthly orders",
    paymentStatusesTables: "Payment statuses of the tables",
    statistics: "Statistics",
    weeklyStatistics: "Weekly statistics",
    // About
    moreInformationAbout: "More information {tag} about us.",
    // Table Managament
    printQrCode: "Print QR code",
    tableNumber: "Table number",
    table: "Table",
    // Live Table
    time: "Time",
    waitersName: "Waiter’s name",
    totalPay: "Total to pay",
    leftPay: "Left to pay",
    remainingPay: "Remaining to pay:",
    totalTable: "Total",
    includesTax: "Includes tax",
    includes: "Includes",
    serviceFee: "Service fee",
    // Payments
    method: "Method",
    tips: "Tips",
    status: "Status",
    actions: "Actions",
    subtotal: "Subtotal",
    // Feedbacks
    rating: "Rating",
    reviewTime: "Review time",
    category: "Category",
    comment: "Comment",
    info: "Info",
    close: "Close",
    // Home
    viewDemo: "View demo",
    aboutUs: "About us",
    ourFeatures: "Our features",
    testimonials: "Testimonials",
    forRestaurants: "For Restaurants",
    contact: "Contact",
    copyrightHome: "Copyright © 2022 {tag}. All rights reserved.",
    contactUsHome: "Contact us.",
    downloadApp: "Download app",
    openApp: "Open the web application and start now!",
    supportApp: "Tapp supports Apple Pay, Google Pay, credit card payment methods which gives your customers more freedom of choice {tag} and much needed flexibility",
    openWebApp: "Open web application",
    aboutUsTitle: "Learn more about us",
    tappApp: "Tapp web application",
    tappAppScan: "Scan it, split it, pay it!",
    letsUsTake: "Let us take that pressure off you.",
    forRestaurantsBenefits: "Benefits for restaurants",
    testimonialsDesc: "They always appreciate us!",
    howWorks: "How it works?",
    howWorksDesc: "Tapp is easy to set up and integrates to your point of sale (POS) system. Our web app {tag} requires no download, no login – just scan and pay and no strings attached.",
    howWorksScan: "Scan a QR code and Start",
    howWorksScanDesc: "Guests simply use their smartphones to scan a QR code on a table. Each QR code is unique to a table based on your table plan, making them easy for your staff to manage and track.",
    howWorksElectronic: "Electronic Receipts",
    howWorksElectronicDesc: "Guests can either download a check to their smartphone or receive it to their email address. ",
    howWorksPayment: "Payment notification",
    howWorksPaymentDesc: "As soon as guests pay, the payment will display in your terminals. No need to chase customers for money! ",
    howWorksNoApp: "No App download required",
    howWorksNoAppDesc: "Web app nature of our solution delivers a frictionless checkout experience that your clients truly deserve.",
    howWorksYourGuests: "Your guests have 3 options to pay",
    howWorksYourGuestsDesc: "Your guests have 3 options to pay: they can either choose items & pay, pay by custom amount, or split by number of people at the table.",
    forRestaurantsBusiness: "Business as usual",
    forRestaurantsBusinessDesc: "Tapp works seamlessly with your existing point of sale system, meaning you won’t need to make any changes to your current workflow. Customers' bills will be updated automatically, and their payments will appear on your system in real time.",
    forRestaurantsGreater: "Greater Customer Service",
    forRestaurantsGreaterDesc: "Your customers will have option to rate the restaurant service at the end of the checkout, so you can use this data to either be proud of what you have accomplished or perfect your business going forward. ",
    forRestaurantsIncreased: "Increased Tipping",
    forRestaurantsIncreasedDesc: "The predefined tipping choices encourage tips with the tap of a button. No more undertipping! ",
    forRestaurantsQuick: "Quick table turnover",
    forRestaurantsQuickDesc: "Your staff has literally more hands now to do what they do best – provide and deliver service. ",
    forRestaurantsReporting: "Reporting",
    forRestaurantsReportingDesc: "Your everyday reporting becomes quick, smart, and efficient. ",
    forRestaurantsTruly: "Truly Paperless",
    forRestaurantsTrulyDesc: "We are a digital payment solution on a mission to save our planet!",
    scanTheDemo: "Scan me to see the Demo!"
  }
};

export default en;