import { Spinner as Loader } from "react-bootstrap";

export const Spinner = ({ full }) => {
  return (
    <>
      {full ? (
        <div className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center z-5000 bg-white bg-opacity-75">
          <span className="position-absolute top-50 start-50 translate-middle text-success spinner ">
            <Loader
              animation="border"
              role="status"
              className="loader-style"
            >
              <span className="visually-hidden">Loading...</span>
            </Loader>
          </span>
        </div>
      ) : (      
        <Loader
          animation="border"
          role="status"
          className="loader-style"
        >
          <span className="visually-hidden">Loading...</span>
        </Loader>
      )}
    </>
  );
};
