import { Container } from "react-bootstrap";
import Header from "./Header";
import Footer from "./Footer";

const PageLayout = ({children}) => {
  return (
    <div className="min-vh-100 d-flex flex-column position-relative">
      <div className="position-relative z-100">
        <Header brand={"success"} />
      </div>
      <main className="position-relative z-100 my-auto pt-3 pt-lg-40 pb-lg-56">
        <Container>
          {children}
        </Container>
      </main>
      <div className="position-relative z-100 mt-auto text-dark">
        <Footer />
      </div>
    </div>
  );
};

export default PageLayout;
