import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { APP_ROUTE } from "utils/constants";

const Menu = () => {
  return (
    <Nav>
      <Nav.Item className="me-lg-2 me-xl-3 me-xxl-32">        
        <NavLink
          to={APP_ROUTE.PRIVACY_POLICY}
          className={({ isActive }) =>
            "nav-link" + (isActive ? " active" : "")
          }>
          <FormattedMessage id="privacyPolicy" />
        </NavLink>
      </Nav.Item>
      <Nav.Item className="me-lg-2 me-xl-3 me-xxl-32">
        <NavLink
          to={APP_ROUTE.ABOUT_TAPP}
          className={({ isActive }) =>
            "nav-link" + (isActive ? " active" : "")
          }>
          <FormattedMessage id="aboutTapp" />
        </NavLink>
      </Nav.Item>
      <Nav.Item className="me-lg-2 me-xl-3 me-xxl-32">
        <NavLink
          to={APP_ROUTE.TERMS_CONDITIONS}
          className={({ isActive }) =>
            "nav-link" + (isActive ? " active" : "")
          }>
          <FormattedMessage id="termsConditiions" />
        </NavLink>
      </Nav.Item>
      <Nav.Item className="me-lg-2 me-xl-3 me-xxl-32">
        <NavLink
          to={APP_ROUTE.TRUST}
          className={({ isActive }) =>
            "nav-link" + (isActive ? " active" : "")
          }>
          <FormattedMessage id="trust" />
        </NavLink>
      </Nav.Item>
      <Nav.Item className="me-lg-2 me-xl-3 me-xxl-32">
        <NavLink
          to={APP_ROUTE.COOKIES_POLICY}
          className={({ isActive }) =>
            "nav-link" + (isActive ? " active" : "")
          }>
          <FormattedMessage id="cookiesPolicy" />
        </NavLink>
      </Nav.Item>
    </Nav>
  );
};

export default Menu;
