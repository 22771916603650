import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const Copyright = () => {
  return (
    <div className="fs-14">
      <FormattedMessage
        id="copyrightHome"
        values={{ tag: <Link to="/" className="text-success fw-bold text-decoration-none">Tapp</Link> }} />
    </div>
  );
};

export default Copyright;
