import { Container, Row, Col } from "react-bootstrap";
import { Helmet, HelmetProvider } from "react-helmet-async";

import Sidebar from "./Sidebar";
import Top from "./Top";

const MainLayout = ({children}) => {
  return (
    <>
      <HelmetProvider><Helmet><style>{"body { background-color: #F4FBFA; }"}</style></Helmet></HelmetProvider>
      <Container fluid className="px-lg-0">
        <Row className="g-0 flex-nowrap">
          <Col xs="auto">
            <div className="d-none d-lg-flex min-vh-100 h-100">
              <Sidebar />
            </div>
          </Col>
          <Col>
            <Top />
            <div className="px-20 px-md-3 px-lg-20 px-xl-5 pb-3">
              {children}
            </div>
          </Col>
        </Row>      
      </Container>
    </>
  );
};

export default MainLayout;
