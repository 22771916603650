import { Container, Row, Col, Button } from "react-bootstrap";
import { useState } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import Menu from "./Menu";

import ContactModal from "./ContactModal";

const Footer = () => {
  const [showContact, setShowContact] = useState(false);

  const handleCloseContact = () => setShowContact(false);
  const handleShowContact = () => setShowContact(true);

  return (
    <>
      <ContactModal show={showContact} onHide={handleCloseContact} />
      <footer className="footer py-4 py-lg-32">
        <Container>
          <Row>
            <Col xs={6}>
              <div className="d-none d-lg-block">
                <Menu />
              </div>

              <div
                className="d-lg-none"
                onClick={handleShowContact}>
                <Button variant="outline-white" size="sm">
                  <FormattedMessage id="contactUs" />
                </Button>
              </div>
            </Col>
            <Col xs={6}>
              <div className="text-secondary text-end fw-500">
                <small><FormattedMessage id="copyright" /> &copy; <Link to="/" className="text-decoration-none d-block d-sm-inline">Tapp App</Link></small>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
