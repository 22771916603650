import { useLocation } from "react-router-dom";

import MainLayoutLogin from "./MainLayoutLogin";
import PageLayout from "./PageLayout";

import HomeLayout from "views/Home/layout/HomeLayout";

import { APP_ROUTE } from "utils/constants";

const MainLayoutLoginWrapper = ({ children }) => {
  const location = useLocation();

  if (location.pathname === APP_ROUTE.HOME) {
    return <HomeLayout>{children}</HomeLayout>;
  }

  if (location.pathname === APP_ROUTE.PRIVACY_POLICY || location.pathname === APP_ROUTE.ABOUT_TAPP || location.pathname === APP_ROUTE.TERMS_CONDITIONS || location.pathname === APP_ROUTE.TRUST || location.pathname === APP_ROUTE.COOKIES_POLICY) {
    return <PageLayout>{children}</PageLayout>;
  }

  return <MainLayoutLogin>{children}</MainLayoutLogin>;
};

export default MainLayoutLoginWrapper;
