import { Nav } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link } from "react-scroll";

const Menu = () => {
  return (
    <Nav>
      <Link
        activeClass="active"
        spy={true}
        smooth={true}
        duration={1000}
        delay={100}
        offset={-30}
        to="about-section"
        className="nav-link text-dark text-decoration-none mx-lg-2 mx-xl-3 mx-xxl-28 position-relative cursor-pointer">
        <FormattedMessage id="aboutUs" />
      </Link>
      <Link
        activeClass="active"
        spy={true}
        smooth={true}
        duration={1000}
        delay={100}
        offset={-30}
        to="features-section"
        className="nav-link text-dark text-decoration-none mx-lg-2 mx-xl-3 mx-xxl-28 position-relative cursor-pointer">
        <FormattedMessage id="ourFeatures" />
      </Link>      
      <Link
        activeClass="active"
        spy={true}
        smooth={true}
        duration={1000}
        delay={100}
        offset={-30}
        to="restaurants-section"
        className="nav-link text-dark text-decoration-none mx-lg-2 mx-xl-3 mx-xxl-28 position-relative cursor-pointer">
        <FormattedMessage id="forRestaurants" />
      </Link>
      <Link
        activeClass="active"
        spy={true}
        smooth={true}
        duration={1000}
        delay={100}
        offset={-30}
        to="testimonials-section"
        className="nav-link text-dark text-decoration-none mx-lg-2 mx-xl-3 mx-xxl-28 position-relative cursor-pointer">
        <FormattedMessage id="testimonials" />
      </Link>
      <Link
        activeClass="active"
        spy={true}
        smooth={true}
        duration={1000}
        delay={100}
        offset={-30}
        to="contact-section"
        className="nav-link text-dark text-decoration-none mx-lg-2 mx-xl-3 mx-xxl-28 position-relative cursor-pointer">
        <FormattedMessage id="contact" />
      </Link>
    </Nav>
  );
};

export default Menu;
