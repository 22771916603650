import { Offcanvas, Button } from "react-bootstrap";
import Icon from "components/Icon";

import Search from "components/Search";

const SearchMob = ({ show, onHide }) => {
  return (
    <Offcanvas show={show} onHide={onHide} placement="top">
      <Offcanvas.Header className="p-4">
        <div className="vstack pe-3">
          <Search />
        </div>
        <Button variant="success-light" className="btn-close" onClick={onHide}>
          <Icon icon="close" className="sz-22" />
        </Button>
      </Offcanvas.Header>
    </Offcanvas>
  );
};

export default SearchMob;
