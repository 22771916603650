import { Offcanvas, Navbar, Button } from "react-bootstrap";
import Icon from "components/Icon";
import Menu from "./Menu";

import logo from "assets/images/Tapp.svg";

const MenuMob = ({ show, onHide }) => {
  return (
    <Offcanvas show={show} onHide={onHide} placement="end">
      <Offcanvas.Header className="pb-32">
        <Offcanvas.Title>
          <Navbar.Brand href="/">
            <img src={logo} alt="Tapp"/>
          </Navbar.Brand>
        </Offcanvas.Title>
        <Button
          variant="success-light"
          className="btn-close"
          onClick={onHide}>
          <Icon icon="close" className="sz-22" />
        </Button>
      </Offcanvas.Header>
      <Offcanvas.Body className="py-0">
        <Menu />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default MenuMob;
