import "./App.scss";
import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { IntlProvider } from "react-intl";
import { LOCALES } from "./i18n/locales";
import messages from "./i18n/messages/index";

import { AuthContext } from "./context/auth.context";
import { MainRoutes, LoginRoutes } from "./routes";
import { useLoginMutation } from "./redux/services/authApi";
import { logout as logoutAction } from "./redux/slices/userSlice";

import MainLayoutWrapper from "./layouts/MainLayoutWrapper";
import MainLayoutLoginWrapper from "./layouts/MainLayoutLoginWrapper";

export default function App() {
  const {
    userStore: {
      user,
      token,
      isLogin,
      languageCode,
      isLoginTest
    }
  } = useSelector((state) => ({ userStore: state.userStore }));

  const dispatch = useDispatch();
  const logout = () => dispatch(logoutAction());
  const [login] = useLoginMutation();
  const isAuthenticated = Boolean(token.access);

  // const locale = isLogin ? languageCode : "en";
  const locale = isLogin ? "en" : "en";

  console.log(languageCode);
  console.log(process.env.REACT_APP_VERSION);

  const toastStyles = {
    minWidth: "fit-content",
    padding: "10px 6px",
    height: "185px",
    overflowY: "auto",
    right: "1rem",
    width: "auto",
    maxWidth: "calc(100% - 2rem)"
  };

  return (
    <div className="App">
      <IntlProvider
        messages={messages[locale]}
        locale={locale}
        defaultLocale={LOCALES.EN}>
        <AuthContext.Provider
          value={{ token, login, logout, user, isAuthenticated, isLoginTest }}
        >
          <ToastContainer style={toastStyles} />

          {/* <BrowserRouter>
            {isAuthenticated && user?.id && (
              <MainLayoutWrapper><MainRoutes /></MainLayoutWrapper>
            )}
            {!isAuthenticated && (
              <MainLayoutLoginWrapper><LoginRoutes /></MainLayoutLoginWrapper>
            )}
          </BrowserRouter> */}
          <BrowserRouter>
            {isLoginTest && (
              <MainLayoutWrapper><MainRoutes /></MainLayoutWrapper>
            )}
            {!isLoginTest && (
              <MainLayoutLoginWrapper><LoginRoutes /></MainLayoutLoginWrapper>
            )}
          </BrowserRouter>
        </AuthContext.Provider>
      </IntlProvider>
    </div>
  );
}
