import { Button, Form, Row, Col, Alert } from "react-bootstrap";
import { useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

import { Formik } from "formik";
import { getContactFormSchema } from "utils/validation/yupContactForm";

import { useContactUsMutation } from "redux/services/authApi";

import { Spinner } from "./Spinner";
import SuccesfullMessageModal from "./SuccesfullMessageModal";

const ContactForm = () => {
  const { formatMessage } = useIntl();

  const [success, setSuccess] = useState(false);
  const [contactUs, { isLoading }] = useContactUsMutation();
  const { userStore: { user } } = useSelector((state) => ({ userStore: state.userStore }));

  const [showSuccess, setShowSuccess] = useState(false);

  const handleCloseSuccess = () => setShowSuccess(false);
  const handleShowSuccess = () => setShowSuccess(true);

  const schema = getContactFormSchema(formatMessage);

  const handleSubmit = async (values, { setStatus, setErrors }) => {
    try {
      const { error } = await contactUs({
        name: user.name,
        email: user.email,
        message: values.message,
        user: user.id
      });
      if (error && error.data.errors.length !== 0) {
        setErrors({
          message: error.data.errors[0].message,
          email: error.data.errors[0].email,
          full_name: error.data.errors[0].full_name,
          user: error.data.errors[0].user
        });
      } else {
        setSuccess(true);
        handleShowSuccess();
      }
    } catch (err) {
      console.error(err);
      setStatus(err.message);
    }
  };

  return (
    <>
      <SuccesfullMessageModal show={showSuccess} onHide={handleCloseSuccess} />

      <Formik
        validateOnChange
        initialValues={{ name: "", email: "", message: "" }}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isValid,
          handleSubmit,
          dirty,
          status
        }) => (
          <Form className="form" onSubmit={handleSubmit}>
            {status && (
              <Alert variant="danger">{status}</Alert >
            )}

            <Row>
              <Col lg={12}>
                <Form.Label htmlFor="name">
                  <FormattedMessage id="nameSurname" />
                </Form.Label>
              </Col>
              <Col lg={12}>
                <Row>
                  <Col lg={12}>
                    <Row>
                      <Col lg={6} className="mb-3">
                        <Form.Group className="position-relative">
                          <div className="position-relative">
                            <Form.Control
                              id="name"
                              type="text"
                              name="name"
                              placeholder="Name"
                              className={`${touched.name ? "is-touch " : ""} ${
                                errors.name && touched.name ? " is-invalid" : ""
                              }`}
                              value={values.name}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </div>
                          {errors.name && touched.name && (
                            <Form.Control.Feedback type="invalid">
                              {errors.name}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={6} className="mb-3">
                        <Form.Group className="position-relative">
                          <div className="position-relative">
                            <Form.Control
                              type="text"
                              name="email"
                              placeholder="E-mail"
                              className={`${touched.email ? "is-touch " : ""} ${
                                errors.email && touched.email ? " is-invalid" : ""
                              }`}
                              value={values.email}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </div>
                          {errors.email && touched.email && (
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={12} className="mb-3">
                    <Form.Group className="position-relative">
                      <div className="position-relative">
                        <Form.Control
                          type="text"
                          as="textarea"
                          rows="5"
                          name="message"
                          placeholder="Your message..."
                          className={`${touched.message ? "is-touch " : ""} ${
                            errors.message && touched.message ? " is-invalid" : ""
                          }`}
                          value={values.message}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                      {errors.message && touched.message && (
                        <Form.Control.Feedback type="invalid">
                          {errors.message}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Button
              type="submit"
              variant="success"
              className="mt-2"
              disabled={!(isValid && dirty) || isLoading}>
                {isLoading && <Spinner />}
                <FormattedMessage id="sendMessage" />
            </Button>            
          </Form>
        )}
      </Formik>

      {success && <div>Ok</div>}
    </>
  );
};

export default ContactForm;
