export const URLS_PROCESSED_IN_COMPONENTS = [
  "/signup/",
  "/token/",
  "/token-phone/",
  "/phone-send/",
  "/phone-verify/"
];

export const AUTH_API = {
  TOKEN: "token/",
  ME: "me/",
  SIGNUP: "signup/",
  PASSWORD_RESET: "password/reset/",
  PASSWORD_RESET_CONFIRM: "password/reset/confirm/",
  CONTACT_US: "contact_us/"
};

export const RE_AUTH = {
  TOKEN_REFRESH: "token/refresh/"
};

export const APP_ROUTE = {
  ENTRY: "/",  
  HOME: "/",
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  LIVE_TABLE: "/live_table",
  PAYMENTS: "/payments",
  TABLE_MANAGEMENT: "/table_management",
  FEEDBACKS: "/feedbacks",
  SETTINGS: "/settings",
  ABOUT: "/about",
  PRIVACY_POLICY: "/privacy_policy",
  ABOUT_TAPP: "/about_tapp",
  TERMS_CONDITIONS: "/terms_conditions",
  TRUST: "/trust",
  COOKIES_POLICY: "/cookies_policy",
  FORGOT: "/forgot",
  VERIFY_CODE: "/verify_code",
  RESET_PASSWORD: "/reset_password",
  RESET_PASSWORD_SUCCESS: "/reset_password_success"
};
